import { Controller } from "@hotwired/stimulus";
import Swiper from 'swiper';
import { Autoplay, Navigation } from 'swiper/modules';

export default class extends Controller {
    static values = {
        loop: Boolean,
        spaceBetween: Number,
        slidesPerView: String,
        autoplay: Boolean,
        autoplayDelay: Number,
        navigationEnabled: Boolean,
        paginationEnabled: Boolean,
        centeredSlides: Boolean,
        breakpoints: Object,
        mobileSpaceBetween: Number,
        mobileSlidesPerView: String,
        mobileCenteredSlides: Boolean,
        initialSlide: Number,
        removelazyloading: Boolean,
    };

    connect() {
        this.initObserver();
        this.throttledResize = throttle(this.reinitializeSwiper.bind(this), 200);
        window.addEventListener('resize', this.throttledResize);
    }

    initObserver() {
        const options = {
            root: null,
            rootMargin: "0px 0px 200px 0px",
            threshold: 0
        };

        this.observer = new IntersectionObserver(this.handleIntersection.bind(this), options);
        this.observer.observe(this.element);
    }

    handleIntersection(entries) {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                this.initializeSwiper();

                this.observer.disconnect(); // Stop observing after initialization
            }
        });
    }

    initializeSwiper() {
        if (this.swiper) {
            return; // Prevent reinitialization if it's already initialized
        }

        const swiperOptions = {
            modules: [Navigation, Autoplay],
            loop: this.hasLoopValue ? this.loopValue : false,
            spaceBetween: this.hasSpaceBetweenValue ? this.spaceBetweenValue : 30,
            slidesPerView: this.hasSlidesPerViewValue ? this.slidesPerViewValue : 1.5,
            centeredSlides: this.hasCenteredSlidesValue ? this.centeredSlidesValue : true,
            autoplay: this.autoplayValue ? { delay: this.hasAutoplayDelayValue ? this.autoplayDelayValue : 3000 } : false,
            initialSlide: this.hasInitialSlideValue ? this.initialSlideValue : 0,
            navigation: this.navigationEnabledValue ? {
                nextEl: this.element.querySelector('.swiper-custom-next'),
                prevEl: this.element.querySelector('.swiper-custom-prev'),
            } : false,
            pagination: this.paginationEnabledValue ? {
                el: this.element.querySelector('.swiper-pagination'),
                clickable: true,
            } : false,
            breakpoints: this.hasBreakpointsValue ? this.breakpointsValue : {
                320: {
                    slidesPerView: this.hasMobileSlidesPerViewValue ? this.mobileSlidesPerViewValue : 1.5,
                    spaceBetween: this.hasMobileSpaceBetweenValue ? this.mobileSpaceBetweenValue : 30,
                    centeredSlides: this.hasMobileCenteredSlidesValue ? this.mobileCenteredSlidesValue : true,
                    initialSlide: 0
                },
                767: {
                    slidesPerView: this.hasSlidesPerViewValue ? this.slidesPerViewValue : 1.5,
                    spaceBetween: this.hasSpaceBetweenValue ? this.spaceBetweenValue : 30,
                },
            },
            lazy: {
                loadOnTransitionStart: true,
                loadPrevNext: true,
            },
            on: {
                slideChangeTransitionStart: () => this.manageImageSources(this.hasRemovelazyloadingValue),
                slideChangeTransitionEnd: () => this.manageImageSources(this.hasRemovelazyloadingValue)
            },
        };
        
        this.swiper = new Swiper(this.element, swiperOptions);

        // Initial call to manage images for the initial slides (active, previous, and next)
        this.manageImageSources(this.hasRemovelazyloadingValue);
    }


    manageImageSources(val = false) {
        if(val) return

        const slides = this.swiper.slides;
        const activeIndex = this.swiper.activeIndex;
        const prevIndex = this.swiper.isBeginning ? slides.length - 1 : activeIndex - 1;
        const nextIndex = this.swiper.isEnd ? 0 : activeIndex + 1;

        slides.forEach((slide, index) => {
            const img = slide.querySelector('img');

            if (img) {
                // Check if the image is already loaded
                if (index === activeIndex || index === prevIndex || index === nextIndex) {
                    const imgSrc = img.getAttribute('data-src');
                    if (!img.getAttribute('src') && imgSrc) {
                        // Load image if it's not loaded yet
                        img.setAttribute('src', imgSrc);
                        img.dataset.loaded = "true"; // Mark image as loaded
                    }
                }
            }
        });
    }
    reinitializeSwiper() {
        if (this.swiper) {
            this.swiper.destroy(true, true);
        }
        console.log('Reinitializing Swiper...');
        this.swiper = null; // Reset swiper before reinitialization
        this.initializeSwiper();

    }

    disconnect() {
        if (this.swiper) {
            this.swiper.destroy();
        }
        if (this.observer) {
            this.observer.disconnect();
        }
        window.removeEventListener('resize', this.throttledResize);
    }


}

// Throttle function to avoid rapid-fire events
function throttle(func, limit) {
    let lastFunc;
    let lastRan;
    return function () {
        const context = this;
        const args = arguments;
        if (!lastRan) {
            func.apply(context, args);
            lastRan = Date.now();
        } else {
            clearTimeout(lastFunc);
            lastFunc = setTimeout(function () {
                if ((Date.now() - lastRan) >= limit) {
                    func.apply(context, args);
                    lastRan = Date.now();
                }
            }, limit - (Date.now() - lastRan));
        }
    }
}
